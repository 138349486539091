import { z } from 'zod';

import { ProviderId, ProviderIdSchema } from './ids';

// IMPORTANT: when adding a new provider you must make sure to include it in the ConnectionProviderNameSchema. Failing to do so will result in a frontend validation error which will prevent features like Performance from working.

type ProviderName =
  | 'ZENDESK'
  | 'TWILIO'
  | 'AIRCALL'
  | 'INTERCOM'
  | 'SALESFORCE'
  | 'FRONT'
  | 'DIXA'
  | 'KUSTOMER'
  | 'TALKDESK'
  | 'HELPSCOUT'
  | 'DIALPAD'
  | 'CSV';

export const ProviderNameToIdMapping: Record<ProviderName, ProviderId> = {
  ZENDESK: 1 as ProviderId,
  TWILIO: 2 as ProviderId,
  AIRCALL: 3 as ProviderId,
  INTERCOM: 4 as ProviderId,
  SALESFORCE: 5 as ProviderId,
  FRONT: 6 as ProviderId,
  DIXA: 7 as ProviderId,
  KUSTOMER: 8 as ProviderId,
  TALKDESK: 9 as ProviderId,
  HELPSCOUT: 10 as ProviderId,
  DIALPAD: 11 as ProviderId,
  CSV: 12 as ProviderId,
};

// TODO: SUR-2879: update the connection controllers/services to use DTOs based on the below Zod schemas rather than types from server/utils/types.ts
export const OAuthConnectionProviderNameSchema = z.enum([
  'aircall',
  'intercom',
  'salesforce',
  'twilio',
  'zendesk',
  'front',
  'dialpad',
]);
export type OAuthConnectionProviderName = z.infer<typeof OAuthConnectionProviderNameSchema>;
export const isOAuthConnectionProvider = (name: string) => {
  return OAuthConnectionProviderNameSchema.safeParse(name).success;
};

export const ApiKeyConnectionProviderNameSchema = z.enum(['dixa', 'kustomer']);
export type ApiKeyConnectionProviderName = z.infer<typeof ApiKeyConnectionProviderNameSchema>;
export const isApiKeyConnectionProvider = (name: string) => {
  return ApiKeyConnectionProviderNameSchema.safeParse(name).success;
};

export const ClientSecretConnectionProviderNameSchema = z.enum(['talkdesk']);
export type ClientSecretConnectionProviderName = z.infer<
  typeof ClientSecretConnectionProviderNameSchema
>;
export const isClientSecretConnectionProvider = (name: string) => {
  return ClientSecretConnectionProviderNameSchema.safeParse(name).success;
};

export const MergeExclusiveConnectionProviderNameSchema = z.enum(['helpscout']);
export type MergeExclusiveConnectionProviderName = z.infer<
  typeof MergeExclusiveConnectionProviderNameSchema
>;

export const CsvConnectionProviderNameSchema = z.enum(['csv']);
export type CsvConnectionProviderName = z.infer<typeof CsvConnectionProviderNameSchema>;

export const ConnectionProviderNameSchema = z.enum([
  ...OAuthConnectionProviderNameSchema.options,
  ...ApiKeyConnectionProviderNameSchema.options,
  ...ClientSecretConnectionProviderNameSchema.options,
  ...MergeExclusiveConnectionProviderNameSchema.options,
  ...CsvConnectionProviderNameSchema.options,
]);
export type ConnectionProviderName = z.infer<typeof ConnectionProviderNameSchema>;

export const MergeSupportedProviderNameSchema = z.enum([
  // ticketing
  'intercom',
  'zendesk',
  'helpscout',
  'dixa',
  'linear', // for testing
  'jira', // for testing
  // hris
  'hibob',
  'workday',
  'lucca',
  'bamboohr',
  'justworks',
  'adp',
  'charlie',
  'humaans',
  'personio',
  'deel', // for testing
]);
export type MergeSupportedProviderName = z.infer<typeof MergeSupportedProviderNameSchema>;

const ConnectionProviderSchema = z.object({
  name: ConnectionProviderNameSchema,
  displayName: z.string().min(1),
  id: ProviderIdSchema,
  isOauth: z.boolean(),
});
export type ConnectionProvider = z.infer<typeof ConnectionProviderSchema>;

export const GetConnectionProvidersRespSchema = ConnectionProviderSchema.array();
export type GetConnectionProvidersResp = z.infer<typeof GetConnectionProvidersRespSchema>;

export const GetConnectionProviderOAuthUrlRespSchema = z.object({
  authorizationUrl: z.string(),
});

export type GetConnectionProviderOAuthUrlResp = z.infer<
  typeof GetConnectionProviderOAuthUrlRespSchema
>;
