import z from 'zod';

import { TimezoneIanaSchema } from './time';
import { convertNullToUndefined, trimmedString } from './utils';

export const GcalSettingsSchema = z.object({
  push: z.object({
    enabled: z.boolean(),
    shifts: z.boolean(),
    activities: z.boolean(),
  }),
  pull: z
    .object({
      enabled: z.boolean(),
      useSearchTerms: z.boolean(),
      searchTerms: z.array(z.string()),
    })
    .refine(
      pull => {
        if (
          pull.useSearchTerms &&
          (!pull.searchTerms?.length || pull.searchTerms[0].length === 0)
        ) {
          return false;
        }
        return true;
      },
      { message: 'Keyword is required for pulling specific events' },
    ),
});

export type GcalSettings = z.infer<typeof GcalSettingsSchema>;

const OrganizationSettingsSchema = z.object({
  shiftPlanningEnabled: convertNullToUndefined(z.boolean()),
  schedulingEnabled: z.boolean(),
  timeOffIcalLink: convertNullToUndefined(trimmedString),
  zendeskSubdomain: convertNullToUndefined(trimmedString),
  salesforceSubdomain: convertNullToUndefined(trimmedString),
  talkdeskSubdomain: convertNullToUndefined(trimmedString),
  gcalSettings: convertNullToUndefined(GcalSettingsSchema),
});

const PersistedOrganizationSettingsSchema = OrganizationSettingsSchema.merge(
  z.object({ id: z.number() }),
);

export const PersistedOrganizationSettingsAndTimezoneSchema =
  PersistedOrganizationSettingsSchema.merge(
    z.object({
      timezone: TimezoneIanaSchema,
    }),
  );

export type OrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;

export type PersistedOrganizationSettingsAndTimezone = z.infer<
  typeof PersistedOrganizationSettingsAndTimezoneSchema
>;

export const GetOrgSettingsRespSchema = PersistedOrganizationSettingsAndTimezoneSchema;
export type GetOrgSettingsResp = z.infer<typeof PersistedOrganizationSettingsAndTimezoneSchema>;

export const UpdateOrgSettingsReqSchema = OrganizationSettingsSchema.partial();
export type UpdateOrgSettingsReq = z.infer<typeof UpdateOrgSettingsReqSchema>;

export const UpdateOrgSettingsRespSchema = PersistedOrganizationSettingsSchema;
