import z, { ZodSchema } from 'zod';

import { ScheduledTaskSchema, ScheduledTaskWithSurferIdSchema } from './activity';
import {
  HolidayIdSchema,
  MergeIdSchema,
  PlanRefSchema,
  RoundRobinIdSchema,
  ScheduleChangeRequestIdSchema,
  ShiftTemplateIdSchema,
  ShiftTemplateRefSchema,
  StreamIdSchema,
  SurferIdSchema,
  TaskIdSchema,
  TaskTemplateRefSchema,
} from './ids';
import { pagedResultsSchema } from './pagination';
import { DurationSchema, Interval, IntervalSchema, TimezoneIanaSchema, dateString } from './time';
import { TimeOffSubtypeSchema } from './time-off';
import { RefSchema, booleanString } from './utils';
import { arrayQueryPreprocessor } from './utils';

/**
 * Describes the plan that generated a time block.
 */
export const OriginPlanSchema = z.object({
  type: z.literal('origin-plan'),
  planRef: RefSchema,
  shiftTemplateRef: RefSchema,
});

export type OriginPlan = z.infer<typeof OriginPlanSchema>;

/**
 * Describes the manual change that created the time block.
 */
export const OriginManualSchema = z.object({
  type: z.literal('origin-manual'),
  /**
   * The identifier of the block that was manually edited.
   *
   * This property is not set if the block was brand new.
   */
  replacedBlockId: z.string().min(1).optional(),
  /**
   * A reference for the shift template used to generate the block.
   *
   * This property is not set if the block was created without using
   * an existing template.
   */
  shiftTemplateRef: RefSchema.optional(),
});

export type OriginManual = z.infer<typeof OriginManualSchema>;

export const OriginCalendarSchema = z.object({
  type: z.literal('origin-calendar'),
  holidayId: HolidayIdSchema,
});

export const OriginMergeSchema = z.object({
  type: z.literal('origin-merge'),
  holidayId: HolidayIdSchema,
  mergeId: MergeIdSchema.optional(),
});

export const OriginChangeRequestSchema = z.object({
  type: z.literal('origin-change-request'),
  requestId: ScheduleChangeRequestIdSchema,
  shiftTemplateRef: ShiftTemplateRefSchema.optional(),
});

/**
 * Describe how a shift was created using the autofill functionaly.
 */
export const OriginAutofillSchema = z.object({
  type: z.literal('origin-autofill'),
  /**
   * Which template was used to instantiate the shifts.
   */
  shiftTemplateRef: ShiftTemplateRefSchema.optional(),
  /**
   * The streams used to figure out the staffing requirements by the autofill logic.
   */
  streamIds: StreamIdSchema.array(),
});

/**
 * All the possible ways to create a time block.
 */
export const OriginSchema = z.discriminatedUnion('type', [
  OriginPlanSchema,
  OriginManualSchema,
  OriginCalendarSchema,
  OriginMergeSchema,
  OriginChangeRequestSchema,
  OriginAutofillSchema,
]);
export type Origin = z.infer<typeof OriginSchema>;

export const isOriginPlan = (input: unknown): input is OriginPlan => {
  return OriginPlanSchema.safeParse(input).success;
};
export const isOriginManual = (input: unknown): input is OriginManual => {
  return OriginManualSchema.safeParse(input).success;
};
export const isPlanOrManualOrigin = (input: unknown): input is OriginManual | OriginPlan => {
  return OriginManualSchema.safeParse(input).success || OriginPlanSchema.safeParse(input).success;
};

/**
 * Describe a single continous break that surfers can take during
 * their shifts. This can happen at any point during the shift.
 */
export const BreakSchema = z.object({
  /**
   * How long the break can last.
   */
  duration: DurationSchema,
});
export type Break = z.infer<typeof BreakSchema>;

export const BlockIdSchema = z.string().min(1).brand<'BlockId'>();
export type BlockId = z.infer<typeof BlockIdSchema>;

export const StateInDraftDeletedSchema = z.object({
  state: z.literal('deleted'),
});

const StateInDraftEditedSchema = z.object({
  state: z.literal('edited'),
  newBlockId: BlockIdSchema,
});

const StateInDraftPlanOverridenSchema = z.object({
  state: z.literal('plan-overriden'),
  planRef: PlanRefSchema,
});

/**
 * A published shift could have been edited and have a different state in draft
 */
const StateInDraftSchema = z.discriminatedUnion('state', [
  StateInDraftEditedSchema,
  StateInDraftDeletedSchema,
  StateInDraftPlanOverridenSchema,
]);

const PublishedStateSchema = z.object({
  published: z.literal(true),
  stateInDraft: StateInDraftSchema.nullable(),
  version: z.number(),
});
export type PublishedState = z.infer<typeof PublishedStateSchema>;

const DraftStateSchema = z.object({
  published: z.literal(false),
});

const PublishedSchema = z.discriminatedUnion('published', [PublishedStateSchema, DraftStateSchema]);
export type Published = z.infer<typeof PublishedSchema>;

const ConflictTypeSchema = z.enum(['fill']);
export type ConflictType = z.infer<typeof ConflictTypeSchema>;

// This object is used by trimmed fill shifts as a reference to the 'original' fill shift, which is the
// block_event stored in the db.
export const OriginalFillBlockSchema = z.object({
  blockId: BlockIdSchema,
  interval: IntervalSchema,
  tasks: z.array(ScheduledTaskSchema).optional(),
});
export type OriginalFillBlockType = z.infer<typeof OriginalFillBlockSchema>;

export const BaseBlockSchema = z.object({
  /**
   * Unique identifier for this block.
   */
  id: BlockIdSchema,

  /**
   * How the block should interact when there is a conflict.
   * - 'fill' means that the block should fill the space in the schedule, often used for on-call
   */
  conflictType: ConflictTypeSchema.optional(),

  /**
   * Where in the schedule the block has been allocated.
   */
  interval: IntervalSchema,
  /**
   * What created this time block (i.e. plan, manual edit, time off).
   */
  origin: OriginSchema,
  /**
   * Whether the block is published or not
   */
  publishedState: PublishedSchema,
  /**
   * If a block has a parentBlockId then we should update it in the schedule
   * projects if and only if we are updating the parent. E.g. Time off in lieu
   * blocks are always associated with a parent assigned shift block, and
   * should be inserted / deleted from projections whenever the associated
   * shift is
   */
  parentBlockId: BlockIdSchema.optional(),

  /**
   * This is currently only used for blocks of conflictType === 'fill'
   * This will be a reference to the fill block 'parent', which is needed to edit/remove in the UI
   * For a fill shift, we split the fill blocks around existing block events.
   */
  originalFillBlock: OriginalFillBlockSchema.optional(),
});

/**
 * A block in the schedule that represents "time in lie u" given in return for
 * working a shift that was assigned via a round robin
 */
export const TimeInLieuBlockSchema = BaseBlockSchema.merge(
  z.object({
    type: z.literal('block-time-in-lieu'),
    /**
     * The surfer assigned to this shift.
     */
    surferId: SurferIdSchema,
    /**
     * See comment on BaseBlockSchema
     */
    parentBlockId: BlockIdSchema,
  }),
);
export type TimeInLieuBlock = z.infer<typeof TimeInLieuBlockSchema>;

/**
 * A shift is a block on the schedule where we expect a surfer
 * to be working.
 */
export const AssignedShiftBlockSchema = BaseBlockSchema.merge(
  z.object({
    type: z.literal('block-assigned-shift'),
    /**
     * The surfer assigned to this shift.
     */
    surferId: SurferIdSchema,
    /**
     * Describes the breaks a surfer can take during the shift.
     */
    breaks: z.array(BreakSchema).optional(),
    /**
     * If the block was generated by a round robin, this object describes where
     * the round robin was at in its rotation
     */
    roundRobinId: RoundRobinIdSchema.optional(),
    /**
     * Assigned shifts can have time off in lieu associated with them
     */
    childBlocks: z.array(TimeInLieuBlockSchema).optional(),
    /**
     * All the surfer's tasks scheduled within this shift.
     *
     * If tasks is `undefined`, tasks scheduling was no attempted for this shift.
     */
    tasks: z.array(ScheduledTaskSchema).optional(),
    /**
     * Origin is narrowed here to only include the origin types we'd see for shift blocks
     */
    origin: z.discriminatedUnion('type', [
      OriginPlanSchema,
      OriginManualSchema,
      OriginChangeRequestSchema,
      OriginAutofillSchema,
    ]),
  }),
);
export type AssignedShiftBlock = z.infer<typeof AssignedShiftBlockSchema>;

/**
 * A block in the schedule that represents a time off
 */
export const TimeOffBlockSchema = BaseBlockSchema.merge(
  z.object({
    type: z.literal('block-time-off'),
    /**
     * The surfer assigned to this shift.
     */
    surferId: SurferIdSchema,
    subtype: TimeOffSubtypeSchema.nullable().optional(),
    remoteSubtype: z.string().nullable().optional(),
  }),
);

export type TimeOffBlock = z.infer<typeof TimeOffBlockSchema>;

/**
 * A block type represents everything that can be placed on a schedule
 * e.g. assigned shifts, time-off, free shifts
 */
export const BlockSchema = z.discriminatedUnion('type', [
  AssignedShiftBlockSchema,
  TimeOffBlockSchema,
  TimeInLieuBlockSchema,
]);

// https://github.com/colinhacks/zod/discussions/672#discussioncomment-5851662
// https://gist.github.com/flofehrenbacher/71f93f7a423cfc11e1dd018325c241ec?permalink_comment_id=4606033#gistcomment-4606033
function makeFilteredArraySchema<T extends ZodSchema>(schema: T, logError: (e: Error) => void) {
  return z.preprocess(val => {
    const array = Array.isArray(val) ? val : [val];
    return array.filter((item: unknown) => {
      const result = schema.safeParse(item);
      if (!result.success) {
        logError(result.error);
      }
      return result.success;
    });
  }, z.array(schema));
}

const getRelaxedAssignedShiftBlockSchema = (logError: (e: Error) => void) =>
  AssignedShiftBlockSchema.merge(
    z.object({ tasks: makeFilteredArraySchema(ScheduledTaskSchema, logError).optional() }),
  );

export const getRelaxedBlockSchema = (logError: (e: Error) => void) =>
  z.discriminatedUnion('type', [
    getRelaxedAssignedShiftBlockSchema(logError),
    TimeOffBlockSchema,
    TimeInLieuBlockSchema,
  ]);

/**
 * A block that is in draft mode
 */
export const DraftBlockSchema = BlockSchema.and(
  z.object({
    publishedState: DraftStateSchema,
  }),
);

export type DraftBlock = z.infer<typeof DraftBlockSchema>;

export const BlockEventIdSchema = z.string().min(1).brand<'EventId'>();
export type BlockEventId = z.infer<typeof BlockEventIdSchema>;

export const GetBlocksRespSchema = pagedResultsSchema(BlockSchema);
export type GetBlocksResp = z.infer<typeof GetBlocksRespSchema>;

// errors not logged here because they would be logged when parsing in the backend
export const RelaxedGetBlocksRespSchema = pagedResultsSchema(getRelaxedBlockSchema(_error => {}));

export type Block = z.infer<typeof BlockSchema>;

/**
 * Represents an event that changes the schedule state.
 * This could be any of the following:
 *
 *  - add a new block
 *  - remove an existing block
 *  - apply a plan
 *  - publish blocks
 *
 * The event'd ID identifies an event whereas a block ID
 * is used to refer to a specific block within a schedule.
 *
 * Two events MUST have different IDs but they can refer to
 * the same block ID. Example:
 *
 *  - Event `abc` adds a block `x`
 *  - Event `def` removes a block `x`
 */

export const BlockEventBaseSchema = z.object({
  id: BlockEventIdSchema,
  blockId: BlockIdSchema.nullable().optional(),
  start: dateString,
  end: dateString,
});

export const AddBlockEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('add'),
  payload: z.object({
    block: BlockSchema,
  }),
});

export const RemoveBlockEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('remove'),
  payload: z.object({
    replaces: BlockSchema,
  }),
});

export const AddTaskBlockEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('add-task'),
  payload: ScheduledTaskWithSurferIdSchema,
});

export const RemoveTaskBlockEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('remove-task'),
  payload: ScheduledTaskWithSurferIdSchema,
});

export const RoundRobinRotationStateSchema = z.object({
  roundRobinId: RoundRobinIdSchema,
  originPivot: dateString,
});
export type RoundRobinRotationState = z.infer<typeof RoundRobinRotationStateSchema>;

export const ApplyPlanEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('apply'),
  payload: z.object({
    plan: PlanRefSchema,
    appliedBy: z.union([z.number(), z.literal('unknown')]),
    includedSurfers: z.array(SurferIdSchema),
    roundRobinRotationStates: z.array(RoundRobinRotationStateSchema).optional(),
    offset: z.number().optional(),
  }),
});

export const ApplyTaskTemplateEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('apply-task-template'),
  payload: z.object({
    template: TaskTemplateRefSchema,
    appliedBy: z.union([z.number(), z.literal('unknown')]),
  }),
});

export const PublishScheduleEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('publish'),
  payload: z.object({
    publishedBy: z.union([z.number(), z.literal('unknown')]),
    publishedAt: dateString.optional(),
    surfers: z.array(SurferIdSchema).optional(),
  }),
});

export const ClearScheduleEventSchema = BlockEventBaseSchema.extend({
  name: z.literal('clear'),
  payload: z.object({
    publishedBy: z.union([z.number(), z.literal('unknown')]),
    clearedAt: dateString.optional(),
    surfers: z.array(SurferIdSchema).optional(),
    includeManualEdits: z.boolean().optional(),
  }),
});

export const BlockEventSchema = z.discriminatedUnion('name', [
  AddBlockEventSchema,
  RemoveBlockEventSchema,
  AddTaskBlockEventSchema,
  RemoveTaskBlockEventSchema,
  ApplyPlanEventSchema,
  PublishScheduleEventSchema,
  ApplyTaskTemplateEventSchema,
  ClearScheduleEventSchema,
]);

export const BlockEventNameSchema = z.union([
  z.literal('add'),
  z.literal('remove'),
  z.literal('add-task'),
  z.literal('remove-task'),
  z.literal('apply'),
  z.literal('apply-task-template'),
  z.literal('publish'),
  z.literal('clear'),
]);

export const BlockTypeSchema = z.enum([
  'block-assigned-shift',
  'block-time-off',
  'block-time-in-lieu',
]);

export type BlockEvent = z.infer<typeof BlockEventSchema>;
export type AddBlockEvent = z.infer<typeof AddBlockEventSchema>;
export type RemoveBlockEvent = z.infer<typeof RemoveBlockEventSchema>;
export type AddTaskBlockEvent = z.infer<typeof AddTaskBlockEventSchema>;
export type RemoveTaskBlockEvent = z.infer<typeof RemoveTaskBlockEventSchema>;
export type ApplyPlanEvent = z.infer<typeof ApplyPlanEventSchema>;
export type ApplyTaskTemplateEvent = z.infer<typeof ApplyTaskTemplateEventSchema>;
export type PublishScheduleEvent = z.infer<typeof PublishScheduleEventSchema>;
export type ClearScheduleEvent = z.infer<typeof ClearScheduleEventSchema>;
export type BlockEventName = z.infer<typeof BlockEventNameSchema>;

export const GetPublishedEventsRespSchema = z.array(PublishScheduleEventSchema);
export type GetPublishedEventsResp = z.infer<typeof GetPublishedEventsRespSchema>;

export const isPublishScheduleEvent = (input: unknown): input is PublishScheduleEvent => {
  return PublishScheduleEventSchema.safeParse(input).success;
};

/** Events that the frontend send to update the schedule
 * These aren't the same events as all block events because we
 * want to limit the ways to edit a schedule externally
 */
const UpdateScheduleAddEventSchema = z.object({
  type: z.literal('add'),
  block: BlockSchema,
});
const UpdateScheduleRemoveEventSchema = z.object({
  type: z.literal('remove'),
  block: BlockSchema,
});

const UpdateScheduleEventSchema = z.discriminatedUnion('type', [
  UpdateScheduleAddEventSchema,
  UpdateScheduleRemoveEventSchema,
]);
export type UpdateScheduleAddEvent = z.infer<typeof UpdateScheduleAddEventSchema>;
export type UpdateScheduleRemoveEvent = z.infer<typeof UpdateScheduleRemoveEventSchema>;
export type UpdateScheduleEvent = z.infer<typeof UpdateScheduleEventSchema>;

export const PostEventsReqSchema = z.object({
  events: z.array(UpdateScheduleEventSchema).nonempty(),
});
export type PostEventsReq = z.infer<typeof PostEventsReqSchema>;

const ScheduleReqSchema = z.object({
  interval: IntervalSchema,
  surfers: z.array(SurferIdSchema),
});

export const PublishScheduleReqSchema = ScheduleReqSchema;
export type PublishScheduleReq = z.infer<typeof PublishScheduleReqSchema>;

export const DuplicationType = z.enum(['overwrite', 'merge']);
export type DuplicationType = z.infer<typeof DuplicationType>;

export const DuplicateDayScheduleReqSchema = z.object({
  daysToDuplicateTo: IntervalSchema,
  startOfDayToDuplicate: dateString,
  surfers: z.array(SurferIdSchema),
  duplicationType: DuplicationType,
});
export type DuplicateDayScheduleReq = z.infer<typeof DuplicateDayScheduleReqSchema>;

export const ClearScheduleReqSchema = ScheduleReqSchema.extend({
  includeManualEdits: z.boolean(),
});
export type ClearScheduleReq = z.infer<typeof ClearScheduleReqSchema>;

export const FindAndReplaceTasksReqSchema = z.object({
  /**
   * List of the task IDs that should be swapped out
   */
  findTaskIds: z.array(TaskIdSchema).min(1),
  /**
   * Whether or not we should fill unscheduled time with the chosen replacement
   * task
   */
  fillUnscheduledTime: z.boolean(),
  /**
   * Task ID of the selected replacement task
   */
  replaceWithTaskId: TaskIdSchema,
  /**
   * The surfers that should be updated
   */
  surfers: z.array(SurferIdSchema).min(1),
  /**
   * The interval that should be updated
   */
  interval: IntervalSchema,
});
export type FindAndReplaceTasksReq = z.infer<typeof FindAndReplaceTasksReqSchema>;

export const CopyShiftReqBodySchema = z.object({
  blockId: BlockIdSchema,
  dayToCopyTo: dateString,
  surferId: SurferIdSchema,
  copyWithActivities: z
    .boolean()
    .describe('Represents whether the activities in a shift should be copied'),
  timezone: TimezoneIanaSchema.optional(),
});
export type CopyShiftReqBody = z.infer<typeof CopyShiftReqBodySchema>;

export const FindAndReplaceTasksRespSchema = z.object({
  updatedSurferIds: z.array(SurferIdSchema),
});
export type FindAndReplaceTasksResp = z.infer<typeof FindAndReplaceTasksRespSchema>;

/**
 * Edit Shifts
 */

export const EditShiftBlockReqSchema = z.object({
  block_id: BlockIdSchema, // the original blockId to edit
  data: AssignedShiftBlockSchema.omit({ tasks: true, breaks: true }),
});

export const EditTimeOffBlockReqSchema = z.object({
  block_id: BlockIdSchema, // the original blockId to edit
  data: TimeOffBlockSchema,
});

export const GetShiftCountsReqSchema = z.object({
  timezone: TimezoneIanaSchema.optional(),
  surfers: z.undefined().or(z.preprocess(arrayQueryPreprocessor, SurferIdSchema.array())),
  shifts: z.undefined().or(z.preprocess(arrayQueryPreprocessor, ShiftTemplateIdSchema.array())),
  published: booleanString.optional(),
});
export type GetShiftCountsReq = z.infer<typeof GetShiftCountsReqSchema>;

export type ShiftCoverageDataRow = {
  interval: Interval;
  coverage: {
    required: number;
    actual: number;
    surplus: number;
  };
};
