import { IconProps as ChakraIconProps, Circle, createIcon } from '@chakra-ui/react';
import { CSSProperties, FC, ReactElement, isValidElement } from 'react';

import cx from '../../lib/cx';
import { GenericComponentProps } from '../types';
import iconSprite from './iconSprite.svg';
import './style.scss';

export const iconNames = [
  'add-item',
  'add-user',
  'add',
  'archive',
  'arrow-down-chunky',
  'arrow-down',
  'arrow-drop-down',
  'arrow-drop-up',
  'arrow-left-long',
  'arrow-left',
  'arrow-right-thin',
  'arrow-right',
  'arrow-swap-horizontal',
  'arrow-up-chunky',
  'arrow-up',
  'arrows-up-down',
  'book',
  'break',
  'briefcase',
  'broom',
  'bucket',
  'calendar-add',
  'calendar-edit',
  'calendar-today',
  'call',
  'chainlink',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clipboard-tick',
  'clock-filled',
  'clock-plus',
  'close-circle',
  'close',
  'copy-success',
  'copy',
  'cross',
  'dashed-circle',
  'direct-send',
  'document-download',
  'dot',
  'double-arrow-left',
  'double-arrow-right',
  'double-arrow-up',
  'double-arrow-down',
  'duplicate',
  'edit-3',
  'edit',
  'ellipsis',
  'emoji-events',
  'emoji',
  'exclamation-point',
  'export',
  'eye-slash',
  'eye',
  'file-download',
  'filter',
  'forecast',
  'gift',
  'glass',
  'google-calendar',
  'google-meet',
  'help-circle',
  'hourglass',
  'info-circle',
  'info',
  'lamp-charge',
  'like',
  'list-check',
  'lock',
  'login',
  'logout',
  'magic-pen',
  'medal-star',
  'medal-star',
  'message-edit',
  'message-question',
  'message-remove',
  'message',
  'messages-2',
  'messages',
  'minus-circle',
  'minus',
  'moon-outline',
  'moon',
  'move-bottom',
  'move-top',
  'moving-star',
  'ms-teams',
  'notification-bing',
  'pause-circle',
  'pause-circle',
  'people',
  'percentage-squircle',
  'pin',
  'pizza',
  'plus',
  'profile-remove',
  'redo',
  'refresh-2',
  'refresh',
  'rotation',
  'schedule',
  'send-message',
  'setting-4',
  'shift-pattern',
  'show-chart',
  'shuffle',
  'sidebar-open',
  'sidebar-close',
  'size',
  'slack',
  'slash',
  'sliders-horizontal',
  'sms-edit',
  'sms-tracking',
  'sms-star',
  'sparkle',
  'spotify',
  'star',
  'status-up',
  'sun',
  'surfer',
  'thunder',
  'tick-circle',
  'tick',
  'timer',
  'undo',
  'user-plus',
  'volume-low',
  'warning-circle-filled',
  'warning-circle',
  'warning',
  'zoom',
] as const;

export type IconName = (typeof iconNames)[number];

export interface IconProps extends GenericComponentProps {
  name: IconName;
  size?: string;
  transform?: CSSProperties['transform'];
  flexShrink?: number;
}

/**
 * Icon component
 *
 * @param params.name - The name (id) of the icon
 */
export const Icon: FC<React.PropsWithChildren<IconProps>> = ({
  name,
  spacing,
  size,
  transform,
  flexShrink,
}) => {
  const _ = 'Icon';
  const classes = cx(_, spacing);

  return (
    <svg
      className={classes}
      style={
        size
          ? {
              width: size,
              height: size,
              transform,
              flexShrink,
            }
          : {
              transform,
              flexShrink,
            }
      }
      data-testid={`icon-${name}`}
    >
      <use xlinkHref={`${iconSprite}#${name}`} />
    </svg>
  );
};

type IconBadgeProps = { name: IconName; sizePx: number };
export const IconBadge = ({ name, sizePx }: IconBadgeProps) => {
  const circleSize = `${sizePx}px`;
  const iconSize = `${sizePx * 0.7}px`;
  return (
    <Circle
      backgroundColor={'gray.5'}
      color="gray.40"
      boxShadow={'0px 1px 2px rgba(178, 178, 185, 0.45)'}
      size={circleSize}
      position="relative"
    >
      <Icon name={name} size={iconSize} />
    </Circle>
  );
};

export const handleIcon = (icon: IconName | ReactElement | undefined) => {
  if (typeof icon === 'string' && iconNames.includes(icon)) {
    return <Icon size="16px" name={icon} />;
  }

  if (isValidElement(icon)) {
    return icon;
  }

  return undefined;
};

/*
  Dialtone Icons
  - The Dialtone icons have only been partially implemented.
  - The ones below are the most common used icons across the app and so they were added with priority
  - The long term solution to the icons is a script that generates svg sprites using a folder of the Dialtone SVGs

  To add an icon:
  - Make a copy of an existing icon below
  - Find the icon in the Dialtone DS: https://dialtone.dialpad.com/design/icons/
  - Name the new component to match the Dialtone name using camel case with no hypens
  - Update the displayName to match
  - Select the icon in the Dialtone library and copy the SVG using the button
  - Replace the path in the new icon below with the SVG
  - Replace the <svg> tag with a fragment <></>
  - Replace any kebab case props with camelcase

*/

const DEFAULT_VIEW_BOX = '0 0 12 12';
const DEFAULT_PROPS: ChakraIconProps = { boxSize: '16px' };

type CreateIconOptions = Parameters<typeof createIcon>[0];
const createCustomIcon = (options: CreateIconOptions) =>
  createIcon({
    ...options,
    viewBox: options.viewBox || DEFAULT_VIEW_BOX,
    defaultProps: options.defaultProps || DEFAULT_PROPS,
  });

export const CalendarDaysIcon = createCustomIcon({
  displayName: 'CalendarDaysIcon',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 7a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5Zm2 0a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5Zm2 0a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5Zm-4 2a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5Zm2 0a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5Zm2 0a.5.5 0 0 1 .5-.5h.005a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3a1.5 1.5 0 0 1 1.5-1.5h1V1a.5.5 0 0 1 1 0v.5h3V1a.5.5 0 0 1 1 0v.5h1A1.5 1.5 0 0 1 11 3v7a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 1 10V3Zm1 0a.5.5 0 0 1 .5-.5h1V3a.5.5 0 0 0 1 0v-.5h3V3a.5.5 0 0 0 1 0v-.5h1a.5.5 0 0 1 .5.5v1.5H2V3Zm.5 7.5A.5.5 0 0 1 2 10V5.5h8V10a.5.5 0 0 1-.5.5h-7Z"
        fill="currentColor"
      ></path>
    </>
  ),
});

export const ChevronsUpDownIcon = createCustomIcon({
  displayName: 'ChevronsUpDownIcon',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.146 7.146a.5.5 0 0 1 .708 0L6 9.293l2.146-2.147a.5.5 0 1 1 .708.708l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5a.5.5 0 0 1 0-.708Zm2.5-5.5a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 1 1-.708.708L6 2.707 3.854 4.854a.5.5 0 1 1-.708-.708l2.5-2.5Z"
      clipRule="evenodd"
    />
  ),
});

export const ClockIcon = createCustomIcon({
  displayName: 'ClockIcon', // Dialpad clock-4
  path: (
    <>
      <g clipPath="url(#dt-icon260a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
        <path d="M6 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM.5 6a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Z"></path>
        <path d="M6 2.5a.5.5 0 0 1 .5.5v2.691l1.724.862a.5.5 0 1 1-.448.894l-2-1A.5.5 0 0 1 5.5 6V3a.5.5 0 0 1 .5-.5Z"></path>
      </g>
      <defs>
        <clipPath id="dt-icon260a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const GlobeIcon = createCustomIcon({
  displayName: 'GlobeIcon',
  path: (
    <>
      <g clipPath="url(#dt-icon183a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.612 5H2.5A1.5 1.5 0 0 1 4 6.5V7a.5.5 0 0 0 .5.5A1.5 1.5 0 0 1 6 9v1.5c.344 0 .678-.039 1-.111V8.5A1.5 1.5 0 0 1 8.5 7h1.889a4.513 4.513 0 0 0-.145-2.5H9a.5.5 0 0 0-.5.5 1.5 1.5 0 0 1-3 0 .5.5 0 0 0-.49-.5 2 2 0 0 1-1.982-1.334A.5.5 0 0 1 3 3v-.354A4.497 4.497 0 0 0 1.612 5Zm2.387-3.032L4 2v.906A1 1 0 0 0 5 3.5 1.5 1.5 0 0 1 6.5 5a.5.5 0 0 0 1 0A1.5 1.5 0 0 1 9 3.5h.742A4.496 4.496 0 0 0 6 1.5c-.719 0-1.398.169-2.001.468ZM10.032 8H8.5a.5.5 0 0 0-.5.5v1.532A4.519 4.519 0 0 0 10.032 8Zm-4.417 3.487a5.5 5.5 0 1 0-.323-.032.5.5 0 0 0 .323.032ZM5 10.389A4.502 4.502 0 0 1 1.5 6h1a.5.5 0 0 1 .5.5V7a1.5 1.5 0 0 0 1.5 1.5.5.5 0 0 1 .5.5v1.389Z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon183a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const HelpCircleIcon = createCustomIcon({
  displayName: 'HelpCircleIcon',
  path: (
    <>
      <g clipPath="url(#dt-icon192a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M6 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM.5 6a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm5.63-1.988a1 1 0 0 0-1.113.654.5.5 0 0 1-.944-.332A2 2 0 0 1 7.96 5c0 .766-.568 1.271-.973 1.541a4.023 4.023 0 0 1-.843.424l-.017.006-.006.002H6.12l-.002.001L5.96 6.5l.158.474a.5.5 0 0 1-.316-.948l.15.448-.15-.448.007-.003a1.803 1.803 0 0 0 .182-.074c.124-.055.284-.135.442-.24.344-.23.527-.474.527-.709a1.002 1.002 0 0 0-.83-.988ZM5.5 8.5A.5.5 0 0 1 6 8h.005a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5Z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon192a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const InboxIcon = createCustomIcon({
  displayName: 'InboxIcon',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.62 1.5h4.76a1.5 1.5 0 0 1 1.342.832l1.725 3.444A.5.5 0 0 1 11.5 6v3a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 9V6a.5.5 0 0 1 .053-.224l1.724-3.443A1.503 1.503 0 0 1 3.62 1.5Zm0 1a.5.5 0 0 0-.447.277v.002L1.81 5.5H4a.5.5 0 0 1 .416.223L5.268 7h1.464l.852-1.277A.5.5 0 0 1 8 5.5h2.19L8.829 2.779v-.002A.5.5 0 0 0 8.38 2.5m2.12 4H8.268l-.852 1.277A.5.5 0 0 1 7 8H5a.5.5 0 0 1-.416-.223L3.732 6.5H1.5V9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V6.5Z"
      clipRule="evenodd"
    />
  ),
});

export const SearchIcon = createCustomIcon({
  displayName: 'SearchIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 1a4.5 4.5 0 1 0 2.81 8.016l1.837 1.838a.5.5 0 1 0 .707-.707L9.016 8.308A4.5 4.5 0 0 0 5.5 1Zm0 1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
      fill="currentColor"
    />
  ),
});

export const SendIcon = createCustomIcon({
  displayName: 'SendIcon',
  path: (
    <>
      <g clipPath="url(#dt-icon213a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.661 1.036a.5.5 0 0 1 .556-.083l9.546 4.597a.5.5 0 0 1 0 .9l-9.546 4.597a.5.5 0 0 1-.684-.63L3.233 6l-1.7-4.417a.5.5 0 0 1 .128-.547ZM4.111 6.5 2.92 9.599 9.355 6.5H4.11Zm5.244-1H4.11L2.92 2.401 9.355 5.5Z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon213a">
          <path fill="#fff" d="M0 0h12v12H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const SettingsIcon = createCustomIcon({
  displayName: 'SettingsIcon',
  path: (
    <>
      <g clipPath="url(#dt-icon206a)" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M6 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM5 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"></path>
        <path d="M4.83.94A1.5 1.5 0 0 1 5.89.5h.22A1.5 1.5 0 0 1 7.61 2v.093a.5.5 0 0 0 .25.429h.001l.215.125a.5.5 0 0 0 .522-.012l.06-.033a1.5 1.5 0 0 1 2.04.552l.11.19a1.502 1.502 0 0 1-.544 2.047l-.072.043-.011.006a.5.5 0 0 0-.246.433v.254a.5.5 0 0 0 .244.431l.066.036.016.008a1.5 1.5 0 0 1 .548 2.046l-.111.198a1.5 1.5 0 0 1-2.04.552l-.064-.035a.5.5 0 0 0-.518-.01l-.216.125a.5.5 0 0 0-.25.429V10a1.5 1.5 0 0 1-1.5 1.5h-.22a1.5 1.5 0 0 1-1.5-1.5v-.09a.5.5 0 0 0-.25-.432h-.001l-.215-.125a.5.5 0 0 0-.52.011l-.062.034a1.5 1.5 0 0 1-2.04-.552l-.11-.19a1.502 1.502 0 0 1 .544-2.047l.072-.043.011-.007a.5.5 0 0 0 .246-.432v-.255a.5.5 0 0 0-.25-.429.506.506 0 0 1-.034-.021l-.057-.038a1.5 1.5 0 0 1-.532-2.039l.11-.19a1.5 1.5 0 0 1 2.04-.553l.068.037a.5.5 0 0 0 .514.009l.216-.126a.5.5 0 0 0 .25-.433V2c0-.398.158-.78.44-1.06Zm4.105 4.934v.252a1.5 1.5 0 0 0 .77 1.313l.06.032a.5.5 0 0 1 .177.68l-.11.195a.5.5 0 0 1-.682.18l-.015-.007-.068-.037a1.5 1.5 0 0 0-1.492.005l-.216.125A1.5 1.5 0 0 0 6.61 9.91V10a.5.5 0 0 1-.5.5h-.22a.5.5 0 0 1-.5-.5v-.09a1.5 1.5 0 0 0-.75-1.298l-.214-.124-.001-.001a1.5 1.5 0 0 0-1.49-.005l-.07.037-.015.008a.5.5 0 0 1-.682-.183l-.11-.19a.5.5 0 0 1 .183-.681l.075-.046a1.5 1.5 0 0 0 .749-1.303v-.257a1.5 1.5 0 0 0-.734-1.28l-.064-.043a.5.5 0 0 1-.21-.7l.11-.189a.5.5 0 0 1 .683-.182l.015.008.067.036a1.5 1.5 0 0 0 1.493-.004l.216-.125a1.5 1.5 0 0 0 .749-1.295V2a.5.5 0 0 1 .5-.5h.22a.5.5 0 0 1 .5.5v.09a1.5 1.5 0 0 0 .75 1.298l.214.124.001.001a1.5 1.5 0 0 0 1.49.006l.07-.038.015-.008a.5.5 0 0 1 .682.183l.11.19a.5.5 0 0 1-.183.681l-.076.046a1.5 1.5 0 0 0-.548.549v.002a1.5 1.5 0 0 0-.2.75Z"></path>
      </g>
      <defs>
        <clipPath id="dt-icon206a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const TrashIcon = createCustomIcon({
  displayName: 'TrashIcon',
  path: (
    <>
      <g clipPath="url(#dt-icon278a)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
        <path d="M5 5a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 5 5Zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 7 5Z"></path>
        <path d="M1.5 2.5a.5.5 0 0 0 0 1H2V10c0 .413.201.784.46 1.041.257.258.628.46 1.04.46h5c.413 0 .784-.202 1.041-.46.258-.258.46-.628.46-1.04V3.5h.5a.5.5 0 0 0 0-1h-2V2c0-.413-.202-.784-.46-1.042C7.784.701 7.413.5 7.001.5H5c-.413 0-.784.201-1.042.46-.258.257-.459.628-.459 1.04v.5h-2ZM3 10a.51.51 0 0 0 .166.334.51.51 0 0 0 .334.166h5a.51.51 0 0 0 .334-.166A.511.511 0 0 0 9 10V3.5H3V10Zm1.5-8a.51.51 0 0 1 .166-.334A.51.51 0 0 1 5 1.5h2a.51.51 0 0 1 .334.166A.51.51 0 0 1 7.5 2v.5h-3V2Z"></path>
      </g>
      <defs>
        <clipPath id="dt-icon278a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const UserIcon = createCustomIcon({
  displayName: 'UserIcon',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM3.5 3.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm-.768 4.232A2.5 2.5 0 0 1 4.5 7h3A2.5 2.5 0 0 1 10 9.5v1a.5.5 0 0 1-1 0v-1A1.5 1.5 0 0 0 7.5 8h-3A1.5 1.5 0 0 0 3 9.5v1a.5.5 0 0 1-1 0v-1a2.5 2.5 0 0 1 .732-1.768Z"
      clipRule="evenodd"
    />
  ),
});

export const UsersIcon = createCustomIcon({
  displayName: 'UsersIcon',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM2 3.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm5.516-2.059a.5.5 0 0 1 .608-.36 2.5 2.5 0 0 1 0 4.843.5.5 0 0 1-.248-.968 1.5 1.5 0 0 0 0-2.907.5.5 0 0 1-.36-.608ZM1.232 7.732A2.5 2.5 0 0 1 3 7h3a2.5 2.5 0 0 1 2.5 2.5v1a.5.5 0 0 1-1 0v-1A1.5 1.5 0 0 0 6 8H3a1.5 1.5 0 0 0-1.5 1.5v1a.5.5 0 0 1-1 0v-1a2.5 2.5 0 0 1 .732-1.768Zm7.784-.292a.5.5 0 0 1 .609-.36A2.5 2.5 0 0 1 11.5 9.5v1a.5.5 0 0 1-1 0v-1a1.5 1.5 0 0 0-1.125-1.45.5.5 0 0 1-.36-.61Z"
      clipRule="evenodd"
    />
  ),
});

export const CalendarXIcon = createCustomIcon({
  displayName: 'CalendarXIcon',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.44 1.94A1.5 1.5 0 0 1 2.5 1.5h1V1a.5.5 0 0 1 1 0v.5h3V1a.5.5 0 0 1 1 0v.5h1A1.5 1.5 0 0 1 11 3v3a.5.5 0 0 1-1 0v-.5H2V10c0 .274.226.5.5.5h3.75a.5.5 0 0 1 0 1H2.5c-.826 0-1.5-.674-1.5-1.5V3c0-.398.158-.78.44-1.06Zm8.414.706A.5.5 0 0 1 10 3v1.5H2V3a.5.5 0 0 1 .5-.5h1V3a.5.5 0 0 0 1 0v-.5h3V3a.5.5 0 0 0 1 0v-.5h1a.5.5 0 0 1 .354.146Z"
        fill="currentColor"
      ></path>
      <path
        d="M8.854 8.146a.5.5 0 1 0-.708.708l.897.896-.897.896a.5.5 0 0 0 .708.708l.896-.897.896.897a.5.5 0 0 0 .708-.708l-.897-.896.897-.896a.5.5 0 0 0-.708-.708l-.896.897-.896-.897Z"
        fill="currentColor"
      ></path>
    </>
  ),
});

export const XCircleIcon = createCustomIcon({
  displayName: 'XCircle',
  path: (
    <>
      <g clipPath="url(#dt-icon171a)" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM.5 6a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Z"
        ></path>
        <path d="M7.854 4.854a.5.5 0 0 0-.707-.707L6 5.293 4.854 4.147a.5.5 0 0 0-.707.707L5.293 6 4.147 7.147a.5.5 0 0 0 .707.707L6 6.707l1.147 1.147a.5.5 0 0 0 .707-.707L6.707 6l1.147-1.146Z"></path>
      </g>
      <defs>
        <clipPath id="dt-icon171a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const Edit2Icon = createCustomIcon({
  displayName: 'Edit2',
  path: (
    <>
      <g clipPath="url(#dt-icon187a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.768.732a1.914 1.914 0 0 1 2.086 3.122l-6.75 6.75a.5.5 0 0 1-.222.128l-2.75.75a.5.5 0 0 1-.614-.614l.75-2.75a.5.5 0 0 1 .129-.222l6.75-6.75a1.91 1.91 0 0 1 .62-.414Zm.382.923a.914.914 0 0 1 .997 1.491L4.5 8.793 3.207 7.5l5.647-5.646a.914.914 0 0 1 .296-.199Zm-7.437 8.632.485-1.777.302-.303L3.793 9.5l-.302.303-1.778.484Z"
          fill="currentColor"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon187a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const ChevronLeftIcon = createCustomIcon({
  displayName: 'ChevronLeft',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.854 2.646a.5.5 0 0 1 0 .708L5.207 6l2.647 2.646a.5.5 0 1 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0Z"
        clipRule="evenodd"
      ></path>
    </>
  ),
});

export const ChevronRightIcon = createCustomIcon({
  displayName: 'ChevronRight',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.146 2.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 1 1-.708-.708L6.793 6 4.146 3.354a.5.5 0 0 1 0-.708Z"
        clipRule="evenodd"
      ></path>
    </>
  ),
});

export const FilterIcon = createCustomIcon({
  displayName: 'Filter',
  path: (
    <>
      <g clipPath="url(#dt-icon26a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M.547 1.29A.5.5 0 0 1 1 1h10a.5.5 0 0 1 .382.823L7.5 6.413V10.5a.5.5 0 0 1-.723.447l-2-1A.5.5 0 0 1 4.5 9.5V6.413L.618 1.823a.5.5 0 0 1-.071-.533Zm1.53.71 3.305 3.907a.5.5 0 0 1 .118.323v2.961l1 .5v-3.46a.5.5 0 0 1 .118-.324L9.923 2H2.078Z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon26a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const SlidersHorizontalIcon = createCustomIcon({
  displayName: 'SlidersHorizontal',
  path: (
    <>
      <g clipPath="url(#dt-icon32a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 2a.5.5 0 0 0-.5-.5h-3V1a.5.5 0 1 0-1 0v2a.5.5 0 0 0 1 0v-.5h3A.5.5 0 0 0 11 2Zm-9.5-.5a.5.5 0 0 0 0 1H5a.5.5 0 1 0 0-1H1.5Zm4 4.5a.5.5 0 0 1 .5-.5h4.5a.5.5 0 1 1 0 1H6a.5.5 0 0 1-.5-.5Zm-4-.5a.5.5 0 0 0 0 1h2V7a.5.5 0 0 0 1 0V5a.5.5 0 1 0-1 0v.5h-2Zm9 4a.5.5 0 1 1 0 1h-2v.5a.5.5 0 1 1-1 0V9a.5.5 0 0 1 1 0v.5h2Zm-9 0a.5.5 0 1 0 0 1H6a.5.5 0 1 0 0-1H1.5Z"
          fill="currentColor"
        ></path>
      </g>
      <defs>
        <clipPath id="dt-icon32a">
          <path fill="#fff" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </>
  ),
});

export const ToolsIcon = createCustomIcon({
  displayName: 'Tools',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.279 1.514A2.5 2.5 0 0 0 5.72 5.029a.5.5 0 0 1-.102.56L2.164 9.042a.56.56 0 0 0 .792.793L6.411 6.38a.5.5 0 0 1 .56-.102 2.5 2.5 0 0 0 3.515-2.558L9.2 5.007a1 1 0 0 1-1.4 0l-.004-.004-.803-.803a1 1 0 0 1 0-1.4l.003-.004L8.28 1.514ZM7.37.554A3.5 3.5 0 0 1 9.44.81a.5.5 0 0 1 .148.81L7.706 3.5l.793.794 1.881-1.882a.5.5 0 0 1 .81.148 3.5 3.5 0 0 1-4.304 4.76l-3.223 3.224a1.56 1.56 0 1 1-2.208-2.207L4.68 5.112A3.5 3.5 0 0 1 7.37.555Z"
        clipRule="evenodd"
      ></path>
    </>
  ),
});
